import React from 'react'
import service1 from '../../assets/images/services/service1.png'
import service2 from '../../assets/images/services/service5.png'
import service3 from '../../assets/images/terms-of-service.png'

const Services = () => {
    return (
        <section className="overview-area ptb-100 pt-0">
            <div className="container">
                <div className="section-title">
                    <h2>Nuestros servicios</h2>
                </div>

                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">Consultoría</span>
                            <h2>Consultoría en TI</h2>
                            <div className="features-text">
                                {/* <h4><i className="flaticon-tick"></i> Define Your Choices</h4> */}
                                <p>Creamos, renovamos y actualizamos procesos con Tecnologías de Información para facilitar la operación y el control de tu negocio.</p>
                            </div>
                            <div className="features-text">
                                {/* <h4><i className="flaticon-tick"></i> Core Development</h4> */}
                                <p>Somos creadores de software y podemos partir de una idea o necesidad para diseñar procesos inteligentes y automatizados para tu operación y control del negocio, asimismo, podemos renovar o actualizar los procesos con los que ya cuentas para mejorar su efectividad.</p>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={service1} alt="about" />
                        </div>
                    </div>
                </div>

                <div className="overview-box">
                    <div className="overview-image">
                        <div className="image">
                            <img src={service2} alt="features" />
                        </div>
                    </div>

                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">Mejora continua</span>
                            <h2>Soluciones con mejora continua</h2>
                            <div className="features-text">
                                <p>Contamos con diversas soluciones en TI ya listas para implementar en tu negocio.</p>
                            </div>
                            <div className="features-text">
                                <p>Desarrollamos soluciones para distintos sectores y las sometemos a programas de actualización y mejora continua, con el propósito de que tu negocio siempre cuente con tecnología de punta.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="overview-box">
                    <div className="overview-content">
                        <div className="content">
                            <span className="sub-title">Listas</span>
                            <h2>ITS LISTED</h2>
                            <p>Hemos creado y mantenemos actualizadas las listas de personas, empresas y países que tu Entidad financiera necesita para cumplir con las Disposiciones en materia de PLD-FT.</p>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Lista de Personas Políticamente Expuestas Nacionales. </h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Lista de Jurisdicciones de alto riesgo (GAFI). </h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Lista de Jurisdicciones con altos índices de corrupción (Paraísos fiscales). </h4>
                            </div>

                            <div className="features-text">
                                <h4><i className="flaticon-tick"></i> Diversas listas de Personas y empresas nacionales e internacionales sancionadas por delitos.</h4>
                            </div>
                        </div>
                    </div>

                    <div className="overview-image">
                        <div className="image">
                            <img src={service3} alt="features" />
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Services