import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img12.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'

const AboutUsContent = () => {
    return (
        <section className="about-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                {/* <span className="sub-title">
                                    <img src={starIcon} alt="banner" />
                                    Nosotros
                                </span> */}
                                <h2> Nuestro equipo </h2>
                                <p>Somos un grupo de colaboradores y consultores en TI creando soluciones innovadoras desde 2016.</p>

                                <ul className="features-list">
                                    <li style={{ minHeight: 138 }}>
                                        <img src={icon4} alt="banner" />
                                        <h3>6+ años</h3>
                                        <p>De experiencia</p>
                                    </li>
                                    {/* <li>
                                        <img src={icon5} alt="banner" />
                                        <h3>45+</h3>
                                        <p>Team members</p>
                                    </li> */}
                                    <li style={{ minHeight: 138 }}>
                                        <img src={icon6} alt="banner" />
                                        <h3>100%</h3>
                                        <p>Orientados al cliente</p>
                                    </li>
                                    {/* <li>
                                        <img src={icon7} alt="banner" />
                                        <h3>80%</h3>
                                        <p>Senior scientist</p>
                                    </li> */}
                                </ul>

                                <h2> Experiencia </h2>
                                <p> Hemos capitalizado experiencia de una gran diversidad de negocios de los distintos sectores, entre los que se destacan entidades del sector financiero.  </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>

            <div className="container">
                <div className="about-inner-area">
                    <div className="row">
                        {/* <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Nuestr</h3>
                                <p>Real innovations and a positive customer experience are the heart of successful communication.</p>
                                
                                <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Activate Listening</li>
                                    <li><i className="flaticon-tick"></i> Brilliant minds</li>
                                    <li><i className="flaticon-tick"></i> Better. Best. Wow!</li>
                                    <li><i className="flaticon-tick"></i> Branding it better!</li>
                                </ul>
                            </div>
                        </div> */}

                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className="about-text">
                                <h3>Misión</h3>
                                <p>Nos hemos propuesto ser un soporte fundamental en TI, con soluciones actuales e innovadoras que coadyuvarán con el crecimiento de tu negocio.</p>

                                {/* <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Creating. Results.</li>
                                    <li><i className="flaticon-tick"></i> Expect more</li>
                                    <li><i className="flaticon-tick"></i> Good thinking</li>
                                    <li><i className="flaticon-tick"></i> In real we trust</li>
                                </ul> */}
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                            <div className="about-text">
                                <h3>Visión</h3>
                                <p>Sabemos que la plena satisfacción de nuestros clientes es la única calificación de servicio que tiene valor, por lo que todos los colaboradores vivimos con una Total Orientación al Cliente. </p>

                                {/* <ul className="features-list">
                                    <li><i className="flaticon-tick"></i> Stay real. Always.</li>
                                    <li><i className="flaticon-tick"></i> We have you covered</li>
                                    <li><i className="flaticon-tick"></i> We turn heads</li>
                                    <li><i className="flaticon-tick"></i> Your brand, promoted</li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>
        </section>
    )
}

export default AboutUsContent;